import * as url from "../endpoints";
import * as ERROR from "constants/error";
import { instance, config, configExcel } from "./createAxios";
import { removeToken } from "../token";
import { CHANGE_SELLER_NOTIFICATIONS_COUNT } from "constants/actionType";
import store from "../../redux/stores";

export const axiosSeller = {
  async getSellerCheck() {
    try {
      const response = await instance.get(url.SELLER_CHECK, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getSellerInfo() {
    try {
      const response = await instance.get(url.SELLER, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getSellerDetails() {
    try {
      const response = await instance.get(url.SELLER_DETAILS, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async updateSellerContactDetails(data) {
    try {
      const response = await instance.put(
        url.SELLER_CONTACT_DETAILS,
        data,
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async updateSellerAddDetails(data) {
    try {
      const response = await instance.put(url.SELLER_ADD_DETAILS, data, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async updateSellerResponsibleDetails(data) {
    try {
      const response = await instance.put(
        url.SELLER_RESPONSIBLE_DETAILS,
        data,
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async updateSellerBankDetails(data) {
    try {
      const response = await instance.put(
        url.SELLER_BANK_DETAILS,
        data,
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async updateSellerAvatarDetails(data) {
    try {
      let formData = new FormData();
      if (data) {
        formData.append("avatar", data, data.name);
      }
      const response = await instance.put(
        url.SELLER_AVATAR_DETAILS,
        formData,
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async updateSellerDpdDetails(data) {
    try {
      const response = await instance.put(url.SELLER_DPD_DETAILS, data, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async deleteSeller() {
    try {
      const response = await instance.delete(url.SELLER_DELETE, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async becomeSellerInfo() {
    try {
      const response = await instance.get(url.BECOME_SELLER_INFO, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async becomeSeller(data) {
    try {
      const response = await instance.post(url.BECOME_SELLER, data, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async getMedicineCreateProduct() {
    try {
      const response = await instance.get(url.MEDICINE_CREATE_PRODUCT);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getCategoriesCreateProduct(id) {
    try {
      const response = await instance.get(url.CATEGORIES_CREATE_PRODUCT(id));
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getMedicineCreateService() {
    try {
      const response = await instance.get(url.MEDICINE_CREATE_SERVICE);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getCategoriesCreateService(id) {
    try {
      const response = await instance.get(url.CATEGORIES_CREATE_SERVICE(id));
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async createProduct(data) {
    try {
      let formData = new FormData();
      formData.append("name", data.name);
      formData.append("brand", data.brand);
      formData.append("type_of_medicine", data.type_of_medicine);
      formData.append("composition", data.composition);
      formData.append("contradictions", data.contradictions);
      formData.append("storage_conditions", data.storage_conditions);
      formData.append("medicine_format", data.medicine_format);
      formData.append("manufacturer_country", data.manufacturer_country);
      formData.append("indications", data.indications.join(", "));
      formData.append("categories", data.categories.join(", "));
      formData.append("symptoms", data.symptoms.join(", "));
      formData.append("year", data.year.join(", "));
      formData.append("length", data.length);
      formData.append("width", data.width);
      formData.append("height", data.height);
      formData.append("weight", data.weight);
      formData.append("count", data.count);
      formData.append("vat", data.vat);
      formData.append("vendor_code", data.vendor_code);
      formData.append("barcode", data.barcode);
      formData.append("price", data.price);
      formData.append("description", data.description);
      formData.append("dosage", data.dosage);
      formData.append("application_methods", data.application_methods);
      formData.append("delivery_type", data.delivery_type);

      data.pic_1 && formData.append("pic_1", data.pic_1, data.pic_1.name);
      data.pic_2 && formData.append("pic_2", data.pic_2, data.pic_2.name);
      data.pic_3 && formData.append("pic_3", data.pic_3, data.pic_3.name);
      data.pic_4 && formData.append("pic_4", data.pic_4, data.pic_4.name);
      data.pic_5 && formData.append("pic_5", data.pic_5, data.pic_5.name);
      data.pic_6 && formData.append("pic_6", data.pic_6, data.pic_6.name);
      data.pic_7 && formData.append("pic_7", data.pic_7, data.pic_7.name);
      data.pic_8 && formData.append("pic_8", data.pic_8, data.pic_8.name);
      data.pic_9 && formData.append("pic_9", data.pic_9, data.pic_9.name);

      const response = await instance.post(
        url.CREATE_PRODUCT,
        formData,
        config
      );
      return response;
    } catch (error) {
      console.error(error.response);
      removeToken(error);
      return error.response;
    }
  },

  async updateProduct(id, data) {
    try {
      let formData = new FormData();
      formData.append("name", data.name);
      formData.append("brand", data.brand);
      formData.append("type_of_medicine", data.type_of_medicine);
      formData.append("composition", data.composition);
      formData.append("contradictions", data.contradictions);
      formData.append("storage_conditions", data.storage_conditions);
      formData.append("medicine_format", data.medicine_format);
      formData.append("manufacturer_country", data.manufacturer_country);
      formData.append("indications", data.indications.join(", "));
      formData.append("categories", data.categories.join(", "));
      formData.append("symptoms", data.symptoms.join(", "));
      formData.append("length", data.length);
      formData.append("width", data.width);
      formData.append("height", data.height);
      formData.append("weight", data.weight);
      formData.append("count", data.count);
      formData.append("vat", data.vat);
      formData.append("year", data.year_classification);

      formData.append("vendor_code", data.vendor_code);
      formData.append("barcode", data.barcode);
      formData.append("price", data.price);
      formData.append("description", data.description);
      formData.append("dosage", data.dosage);
      formData.append("application_methods", data.application_methods);
      formData.append("delivery_type", data.delivery_type);
      formData.append("old_photo", data.old_photo.join(", "));

      data.pic_1 && formData.append("pic_1", data.pic_1, data.pic_1.name);
      data.pic_2 && formData.append("pic_2", data.pic_2, data.pic_2.name);
      data.pic_3 && formData.append("pic_3", data.pic_3, data.pic_3.name);
      data.pic_4 && formData.append("pic_4", data.pic_4, data.pic_4.name);
      data.pic_5 && formData.append("pic_5", data.pic_5, data.pic_5.name);
      data.pic_6 && formData.append("pic_6", data.pic_6, data.pic_6.name);
      data.pic_7 && formData.append("pic_7", data.pic_7, data.pic_7.name);
      data.pic_8 && formData.append("pic_8", data.pic_8, data.pic_8.name);
      data.pic_9 && formData.append("pic_9", data.pic_9, data.pic_9.name);

      const response = await instance.put(
        url.UPDATE_PRODUCT(id),
        formData,
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async updateService(id, data) {
    try {
      let formData = new FormData();

      for (let key in data) {
        if (
          (key === "type_of_medicine" ||
            key === "city" ||
            key === "types_of_service" ||
            key === "old_photo") &&
          typeof data[key] === "object"
        ) {
          formData.append([key], data[key].join(", "));
        } else {
          if (typeof data[key] === "object") {
            formData.append([key], data[key], data[key].name);
          } else {
            formData.append([key], data[key]);
          }
        }
      }

      const response = await instance.put(
        url.UPDATE_SERVICE(id),
        formData,
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async createService(data) {
    try {
      let formData = new FormData();

      for (let key in data) {
        if (
          (key === "type_of_medicine" ||
            key === "city" ||
            key === "types_of_service") &&
          typeof data[key] === "object"
        ) {
          formData.append([key], data[key].join(", "));
        } else {
          if (typeof data[key] === "object") {
            formData.append([key], data[key], data[key].name);
          } else {
            formData.append([key], data[key]);
          }
        }
      }

      const response = await instance.post(
        url.CREATE_SERVICE,
        formData,
        config
      );
      return response;
    } catch (error) {
      console.error(error.response);
      removeToken(error);
      return error.response;
    }
  },
  async getRawProduct(id) {
    try {
      const response = await instance.get(url.GET_RAW_PRODUCT(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getRawService(id) {
    try {
      const response = await instance.get(url.GET_RAW_SERVICE(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async sendTrackCode(id, data) {
    try {
      const response = await instance.put(
        url.SEND_TRACK_CODE(id),
        data,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getSellerProducts() {
    try {
      const response = await instance.get(url.SELLER_PRODUCTS, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getSellerNotificationsCount() {
    try {
      const response = await instance.get(
        url.GET_SELLER_NOTIFICATIONS_COUNT(),
        config
      );
      store.dispatch({
        type: CHANGE_SELLER_NOTIFICATIONS_COUNT,
        sellerNotificationsCount: response?.data?.count || 0,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getSellerNotifications(page = 1) {
    try {
      const response = await instance.get(
        url.GET_SELLER_NOTIFICATIONS(page),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },

  async getPromotionsDiscount(period, creation_type) {
    try {
      const response = await instance.get(
        url.PROMOTIONS_DISCOUNT +
          "type=" +
          period +
          "&creation_type=" +
          creation_type,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async setNotificationIsSeen(id) {
    try {
      const response = await instance.put(
        url.SET_NOTIFICATION_IS_SEEN(id),
        {},
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getPromotionsDiscountInfo(id) {
    try {
      const response = await instance.get(
        url.PROMOTIONS_DISCOUNT_INFO(id),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getProductsPromotionsDiscount(id) {
    try {
      const response = await instance.get(
        url.PRODUCTS_PROMOTIONS_DISCOUNT(id),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async addProductsPromotionsDiscount(id, data) {
    try {
      const response = await instance.put(
        url.ADD_PRODUCTS_PROMOTIONS_DISCOUNT(id),
        { products: data },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async deleteProductsPromotionsDiscount(id, product) {
    try {
      const response = await instance.put(
        url.DELETE_PRODUCTS_PROMOTIONS_DISCOUNT(id),
        { product },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getFunnel(period) {
    try {
      const response = await instance.get(url.FUNNEL_ANALYTICS(period), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getFunnelExcel(period) {
    try {
      const response = await instance.get(
        url.FUNNEL_EXCEL_ANALYTICS(period),
        configExcel
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getProductAnalytics(id, period) {
    try {
      const response = await instance.get(
        url.PRODUCT_ANALYTICS(id, period),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },

  async getServiceAnalytics(id, params = "") {
    try {
      const response = await instance.get(
        url.GET_SERVICE_ANALYTICS(id, params),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getProductExcel(id, period) {
    try {
      const response = await instance.get(
        url.PRODUCT_EXCEL_ANALYTICS(id, period),
        configExcel
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },

  async getClients(period) {
    try {
      const response = await instance.get(url.CLIENT_ANALYTICS(period), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getServiceAnalyticsExcel(id, params = "") {
    try {
      const response = await instance.get(
        url.GET_SERVICE_ANALYTICS_EXCEL(id, params),
        configExcel
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },

  async getClientsExcel(period) {
    try {
      const response = await instance.get(
        url.CLIENT_EXCEL_ANALYTICS(period),
        configExcel
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getMain(period) {
    try {
      const response = await instance.get(url.MAIN_ANALYTICS(period), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getFinancialExcel(period) {
    try {
      const response = await instance.get(
        url.FINANCIAL_EXCEL(period),
        configExcel
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getTrandAnalytics(period, type) {
    try {
      const response = await instance.get(
        url.GET_TREND_ANALYTICS(period, type),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getRelationshipsAnalytics() {
    try {
      const response = await instance.get(
        url.GET_RELATIONSHIPS_ANALYTICS,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getSeasonalityAnalytics() {
    try {
      const response = await instance.get(
        url.GET_SEASONALITY_ANALYTICS,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getTerminals(address = "") {
    try {
      const response = await instance.get(
        url.GET_SELLER_TERMINALS(address),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async createPromotion(promotion = {}) {
    try {
      const response = await instance.post(
        url.CREATE_PROMOTION(),
        promotion,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async updatePromotion(id, promotion = {}) {
    try {
      const response = await instance.put(
        url.UPDATE_PROMOTION(id),
        promotion,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async deletePromotion(id) {
    try {
      const response = await instance.delete(url.DELETE_PROMOTION(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getRawPromotion(id) {
    try {
      const response = await instance.get(url.GET_RAW_PROMOTION(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getProductsForCreatePromotion(type = "create", promotionId) {
    try {
      const response = await instance.get(
        `${url.GET_PRODUCTS_FOR_CREATE_PROMOTION()}?type=${type}&promotion=${promotionId}`,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
};
