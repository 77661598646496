import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  useNavigate,
  useSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";

import * as links from "constants/url";
import {
  CHANGE_IS_OPEN_AUTH_MODAL,
  CHANGE_IS_OPEN_CITY_MODAL,
} from "constants/actionType";
import store from "../../redux/stores";
import { useSelector } from "react-redux";

import track_orders from "assets/img/track_orders.svg";
import purchases from "assets/img/purchases.svg";
import reviews from "assets/img/reviews.svg";
import favorite_gray from "assets/img/favorite_gray.svg";

import styles from "./Header.module.scss";
import location from "assets/img/location.png";
import location_gray from "assets/img/location.svg";
import brg from "assets/img/brg.svg";

import seller from "assets/img/seller.png";
import search_icon from "assets/img/search_icon.png";
import profile from "assets/img/profile.svg";
import wiki from "assets/img/shalfey.svg";
import favorite from "assets/img/favorite.svg";
import cart from "assets/img/cart.svg";
import { modalUserButtons } from "constants/profileButton";

import { vw } from "plugins/convertPXtoVHVW";

import {
  Link,
  ModalAuth,
  Input,
  Logo,
  Button,
  ModalWindow,
  ModalCity,
  MainMenu,
} from "components";
import { axiosSeller } from "plugins/axios";
import { BecomeSeller } from "pages";

const Header = ({ isSeller = false, staticPages }) => {
  const navigate = useNavigate();
  const { tab: productsTab } = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [isSellerProfile, setIsSellerProfile] = useState(false);
  const countInBasket = useSelector((state) => state.countInBasket);
  const isOpen = useSelector((state) => state.isOpenAuthModal);
  const isOpenCity = useSelector((state) => state.isOpenCityModal);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenBecomeSeller, setIsOpenBecomeSeller] = useState(false);

  const [searchText, setSearchText] = useState("");
  const imageArray = [purchases, track_orders, favorite_gray, reviews];
  const updateProductName = async () =>
    navigate(`catalogue/search?name=${searchText}`);

  const setSellerProfile = async () => {
    if (localStorage.getItem("token") !== null) {
      let response = await axiosSeller.getSellerCheck();
      switch (response.status) {
        case "Seller approved":
          setIsSellerProfile(true);
          break;
        default:
          setIsSellerProfile(false);
      }
    }
  };

  useEffect(() => {
    setSellerProfile();
  }, []);

  useEffect(() => {
    setSearchText(searchParams.get("name") || "");
  }, [searchParams.get("name")]);
  useEffect(() => {
    setIsOpenMenu(false);
  }, [pathname, searchParams.get("types_of_medicine")]);

  return (
    <header className={styles.header}>
      <div
        className={isSeller ? styles.seller : styles.first_header}
        style={{
          backgroundColor: isSeller ? "#fff" : "#5F6B69",
          width: "100%",
        }}
      >
        {staticPages ? (
          <Link path={process.env.REACT_APP_USER_URL}>
            <Logo />
          </Link>
        ) : (
          isSeller && (
            <Link path={process.env.REACT_APP_USER_URL}>
              <Logo isSeller />
            </Link>
          )
        )}
        <div
          className={styles.first_header}
          style={{
            backgroundColor: isSeller ? "#fff" : "#5F6B69",
            width: isSeller ? "auto" : "100%",
          }}
        >
          {!isSeller && (
            <Button
              text={
                <>
                  <img
                    src={location}
                    alt="Местоположение"
                    style={{ height: "1.5vh", marginRight: vw(3) }}
                  />
                  {localStorage.getItem("city") || "Выберите город"}
                </>
              }
              marginRight={vw(70)}
              bgColor="unset"
              fontWeight="400"
              click={() => {
                store.dispatch({
                  type: CHANGE_IS_OPEN_CITY_MODAL,
                  isOpenCityModal: true,
                });
              }}
            />
          )}
          <Link
            path={process.env.REACT_APP_USER_URL + links.ABOUT_US.url}
            className={styles.link}
            color={isSeller ? "#343B37" : "#fff"}
            marginRight={isSeller && "38px"}
          >
            {links.ABOUT_US.title}
          </Link>
          <Link
            path="mailto:info@ecoshalf.com"
            className={styles.link}
            color={isSeller ? "#343B37" : "#fff"}
            marginRight={isSeller && "38px"}
          >
            {links.CONTACTS.title}
          </Link>
          <Link
            path={
              process.env.REACT_APP_USER_URL +
              links.BUYERS.url +
              links.HOW_TO_MAKE_ORDER.url
            }
            className={styles.link}
            color={isSeller ? "#343B37" : "#fff"}
            marginRight={isSeller && "38px"}
          >
            {links.BUYERS.title}
          </Link>
          {isSeller ? (
            <>
              <Button
                text="Личный кабинет"
                bgColor="#F2F7F4"
                color="#343B37"
                padding="12px 22px"
                click={() => {
                  if (localStorage.getItem("token") === null) {
                    store.dispatch({
                      type: CHANGE_IS_OPEN_AUTH_MODAL,
                      isOpenAuthModal: true,
                    });
                  } else {
                    window.location.href =
                      process.env.REACT_APP_USER_URL +
                      links.PROFILE.url +
                      "?tab=trackOrder";
                  }
                }}
              />
              <Button
                text="Регистрация"
                marginLeft="38px"
                marginRight="56px"
                padding="12px 14px"
                click={() => {
                  if (localStorage.getItem("token") === null) {
                    store.dispatch({
                      type: CHANGE_IS_OPEN_AUTH_MODAL,
                      isOpenAuthModal: true,
                    });
                  } else if (isSellerProfile) {
                    navigate(links.PROFILE.url);
                  } else {
                    navigate(links.BECOME_SELLER.url);
                  }
                }}
              />
            </>
          ) : (
            <Link
              className={styles.link}
              color={isSeller ? "#343B37" : "#fff"}
              onClick={() => {
                let token = localStorage.getItem("token");
                let refresh_token = localStorage.getItem("refresh_token");

                const sellerUrl = new URL(
                  process.env.REACT_APP_SELLER_URL + links.INSTRACTION.url
                );
                sellerUrl.searchParams.append("token", token);
                sellerUrl.searchParams.append("refresh_token", refresh_token);

                window.location.href = sellerUrl.toString();
              }}
            >
              <img
                src={seller}
                alt={links.INSTRACTION.title}
                style={{ marginRight: "0.5vw", height: "1.4vh" }}
              />
              {links.INSTRACTION.title}
            </Link>
          )}
        </div>
      </div>

      <ModalWindow
        isOpen={isOpen}
        width={isMobile ? "100vw" : "25vw"}
        onClose={() =>
          store.dispatch({
            type: CHANGE_IS_OPEN_AUTH_MODAL,
            isOpenAuthModal: false,
          })
        }
      >
        <ModalAuth />
      </ModalWindow>
      <ModalWindow
        isOpen={isOpenCity}
        width={isMobile ? "100vw" : "25vw"}
        onClose={() =>
          store.dispatch({
            type: CHANGE_IS_OPEN_CITY_MODAL,
            isOpenCityModal: false,
          })
        }
      >
        <ModalCity
          onClose={() =>
            store.dispatch({
              type: CHANGE_IS_OPEN_CITY_MODAL,
              isOpenCityModal: false,
            })
          }
        />
      </ModalWindow>
      {pathname.includes("wiki") ? (
        <ModalWindow
          isOpen={isOpenMenu}
          isWikiPage={true}
          width="90vw"
          onClose={() => setIsOpenMenu(false)}
        >
          <div className={styles.header__main_menu}>
            <MainMenu
              typeOfMedicine={searchParams.get("types_of_medicine")}
              titleMedicines="Познай свой мир медицины"
              selectNews={pathname.includes("/news")}
              selectForum={pathname.includes("/forum")}
              visibleNews={true}
              visibleServices={false}
              visibleForum={true}
              isArticles={true}
            />
          </div>
        </ModalWindow>
      ) : (
        <ModalWindow
          isOpen={isOpenMenu}
          isLogo
          width="90vw"
          backgroundColor="#5F6B69"
          onClose={() => setIsOpenMenu(false)}
        >
          <div style={{ margin: "3vh 5vw" }}>
            <Button
              marginTop="4vh"
              text={
                <>
                  <img
                    src={location}
                    alt="Местоположение"
                    style={{ height: "1.5vh", marginRight: "2vw" }}
                  />
                  {localStorage.getItem("city") || "Выберите город"}
                </>
              }
              bgColor="unset"
              color="white"
              fontWeight="400"
              click={() => {
                setIsOpenMenu(false);
                store.dispatch({
                  type: CHANGE_IS_OPEN_CITY_MODAL,
                  isOpenCityModal: true,
                });
              }}
            />
            <MainMenu typeOfMedicine={productsTab} white />
            <Link
              className={styles.link}
              color={isSeller ? "#343B37" : "#fff"}
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenBecomeSeller(true);
              }}
            >
              <img
                src={seller}
                alt={links.BECOME_SELLER.title}
                style={{ marginRight: "2vw", height: "1.4vh" }}
              />
              {links.BECOME_SELLER.title}
            </Link>
          </div>
        </ModalWindow>
      )}

      <ModalWindow
        isOpen={isOpenBecomeSeller}
        width="100%"
        backgroundColor="#EAF8E7"
        onClose={() => setIsOpenBecomeSeller(false)}
      >
        {isOpenBecomeSeller && (
          <BecomeSeller
            onClose={() => {
              store.dispatch({
                type: CHANGE_IS_OPEN_AUTH_MODAL,
                isOpenAuthModal: true,
              });
              setIsOpenBecomeSeller(false);
            }}
          />
        )}
      </ModalWindow>
      {!isSeller && (
        <div className={styles.second_header}>
          <Link path="/">
            <Logo marginLeft="5.1vh" />
          </Link>
          <div className={styles.second_header_div}>
            <div className={styles.search_box__input}>
              <Input
                placeholder="Искать товары"
                value={searchText}
                prepend={
                  <img
                    src={search_icon}
                    style={{ height: "2vh" }}
                    alt="search"
                    onClick={updateProductName}
                  />
                }
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") updateProductName();
                }}
              />
            </div>
            <div className={styles.icon}>
              {window.location.pathname === links.PROFILE.url ? (
                <>
                  <img src={profile} alt="Профиль" />
                  <p className={styles.select_item}>
                    {localStorage.getItem("token") === null
                      ? "Войти"
                      : localStorage.getItem("name")}
                  </p>
                </>
              ) : (
                <>
                  <span
                    className={styles.icon}
                    style={{ marginRight: "0", cursor: "pointer" }}
                    onClick={() => {
                      if (localStorage.getItem("token") === null) {
                        store.dispatch({
                          type: CHANGE_IS_OPEN_AUTH_MODAL,
                          isOpenAuthModal: true,
                        });
                      } else {
                        navigate(links.PROFILE.url + "?tab=trackOrder");
                      }
                    }}
                  >
                    <div className={styles.image_profile} />
                    <p>
                      {localStorage.getItem("token") === null
                        ? "Войти"
                        : localStorage.getItem("name")}
                    </p>
                  </span>
                  {localStorage.getItem("token") && (
                    <div className={styles.modal_user}>
                      <div className={styles.name}>
                        <div className={styles.circle}>
                          <p>{Array.from(localStorage.getItem("name"))[0]}</p>
                        </div>
                        <h1>{localStorage.getItem("name")}</h1>
                      </div>
                      <div className={styles.horizontal_line} />
                      {modalUserButtons.map((item, index) => (
                        <div
                          key={index}
                          className={styles.tab}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/profile?tab=" + item.type)}
                        >
                          <img src={imageArray[index]} alt={item.title} />
                          <p>{item.title}</p>
                        </div>
                      ))}
                      <div className={styles.horizontal_line} />
                      <div
                        className={styles.exit}
                        onClick={() => {
                          localStorage.removeItem("name");
                          localStorage.removeItem("token");
                          localStorage.removeItem("refresh_token");
                          localStorage.removeItem("history");
                          navigate("/");
                        }}
                      >
                        Выход
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <Link path={links.ARTICLES.url}>
              <div className={styles.icon}>
                {window.location.pathname === links.ARTICLES.url ? (
                  <>
                    <img src={wiki} alt="Шалфей.Вики" />
                    <p className={styles.select_item}>{links.ARTICLES.title}</p>
                  </>
                ) : (
                  <>
                    <div className={styles.image_shalfey} />
                    <p>{links.ARTICLES.title}</p>
                  </>
                )}
              </div>
            </Link>
            <Link path={links.FAVORITE.url}>
              <div className={styles.icon}>
                {window.location.pathname === links.FAVORITE.url ? (
                  <>
                    <img src={favorite} alt="Избранное" />
                    <p className={styles.select_item}>{links.FAVORITE.title}</p>
                  </>
                ) : (
                  <>
                    <div className={styles.image_favorite} />
                    <p>{links.FAVORITE.title}</p>
                  </>
                )}
              </div>
            </Link>
            <Link path={links.BASKET.url}>
              <div className={`${styles.icon} ${styles.btn_basket}`}>
                {window.location.pathname === links.BASKET.url ? (
                  <>
                    <img src={cart} alt="Корзина" />
                    <p className={styles.select_item}>{links.BASKET.title}</p>
                    {!!countInBasket && (
                      <span className={styles.basket_count}>
                        {countInBasket <= 99 ? countInBasket : "99+"}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    <div className={styles.image_cart} />
                    <p>{links.BASKET.title}</p>
                    {!!countInBasket && (
                      <span className={styles.basket_count}>
                        {countInBasket <= 99 ? countInBasket : "99+"}
                      </span>
                    )}
                  </>
                )}
              </div>
            </Link>
            {window.location.pathname.startsWith(links.PROFILE.url) ? (
              isSellerProfile ? (
                <Button
                  text="Профиль продавца"
                  bgColor="#F2F7F4"
                  color="#5F6B69"
                  padding="1.5vh 1vw"
                  marginRight="4vw"
                  click={() => {
                    let token = localStorage.getItem("token");
                    let refresh_token = localStorage.getItem("refresh_token");

                    const sellerUrl = new URL(
                      process.env.REACT_APP_SELLER_URL +
                        links.SELLER_PROFILE.url
                    );
                    sellerUrl.searchParams.append("token", token);
                    sellerUrl.searchParams.append(
                      "refresh_token",
                      refresh_token
                    );

                    window.location.href = sellerUrl.toString();
                  }}
                />
              ) : (
                <></>
              )
            ) : window.location.pathname.includes("wiki") ? (
              <Button
                text={links.MY_ARTICLES.title}
                padding="1.2vh 2.5vh 1.5vh 2.5vh"
                marginRight="5.1vh"
                height="5vh"
                pressStyle={
                  window.location.pathname === links.CREATE_ARTICLE.url
                }
                click={() => navigate(links.MY_ARTICLES.url)}
              />
            ) : (
              <Button
                text="Анкета"
                prepend={
                  <svg
                    style={{ width: "2.1vh", height: "1.3vh" }}
                    viewBox="0 0 21 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="0.897516"
                      cy="0.897516"
                      r="0.897516"
                      fill={
                        window.location.pathname === links.QUESTIONNAIRE.url
                          ? "#fff"
                          : "#20B900"
                      }
                    />
                    <rect
                      x="3.58984"
                      width="17.0528"
                      height="1.79503"
                      rx="0.897516"
                      fill={
                        window.location.pathname === links.QUESTIONNAIRE.url
                          ? "#fff"
                          : "#20B900"
                      }
                    />
                    <circle
                      cx="0.897516"
                      cy="6.28253"
                      r="0.897516"
                      fill={
                        window.location.pathname === links.QUESTIONNAIRE.url
                          ? "#fff"
                          : "#20B900"
                      }
                    />
                    <rect
                      x="3.58984"
                      y="5.38501"
                      width="17.0528"
                      height="1.79503"
                      rx="0.897516"
                      fill={
                        window.location.pathname === links.QUESTIONNAIRE.url
                          ? "#fff"
                          : "#20B900"
                      }
                    />
                    <circle
                      cx="0.897516"
                      cy="11.6678"
                      r="0.897516"
                      fill={
                        window.location.pathname === links.QUESTIONNAIRE.url
                          ? "#fff"
                          : "#20B900"
                      }
                    />
                    <rect
                      x="3.58984"
                      y="10.7703"
                      width="17.0528"
                      height="1.79503"
                      rx="0.897516"
                      fill={
                        window.location.pathname === links.QUESTIONNAIRE.url
                          ? "#fff"
                          : "#20B900"
                      }
                    />
                  </svg>
                }
                disabled={
                  window.location.pathname === links.QUESTIONNAIRE.url
                    ? true
                    : false
                }
                padding="1.2vh 1vw"
                marginRight="4vw"
                height="5vh"
                newLight
                click={() => navigate(links.QUESTIONNAIRE.url)}
              />
            )}
          </div>
          {isMobile && (
            <>
              <Button
                text={
                  <>
                    <img
                      src={location_gray}
                      alt="Местоположение"
                      style={{ height: "1.5vh", marginRight: vw(3) }}
                    />
                    {localStorage.getItem("city") || "Выберите город"}
                  </>
                }
                bgColor="unset"
                color="#5F6B69"
                fontWeight="400"
                click={() => {
                  store.dispatch({
                    type: CHANGE_IS_OPEN_CITY_MODAL,
                    isOpenCityModal: true,
                  });
                }}
              />
              <img src={brg} alt="Меню" onClick={() => setIsOpenMenu(true)} />
            </>
          )}
        </div>
      )}
    </header>
  );
};

export { Header };
