import styles from "./Service.module.scss";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axiosProduct } from "plugins/axios";
import { MainMenu, ServiceInfo, Link } from "components";
import slide_right from "assets/img/slide_right.svg";
import { Helmet } from "react-helmet";

const Service = () => {
  const [service, setService] = useState({});
  const params = useParams();

  const getService = async () => {
    let result = await axiosProduct.getService(params.id);
    setService(result.info || {});
  };

  useEffect(() => {
    getService();
  }, [params.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <MainMenu />
      </div>
      <Helmet>
        <title>{service.name ? "Услуга " + service.name : "Услуга"}</title>

        <meta
          name="description"
          content={
            "Shalfey : " +
            service.name +
            ". " +
            String(service.description).substring(0, 50)
          }
        />
        <link rel="canonical" href={`/service/${params.id}`} />
      </Helmet>
      <div className={styles.section}>
        <h3 className={styles.heading}>
          <Link path="/">Главная</Link>
          <img src={slide_right} alt="Стрелка" style={{ margin: "0 10px" }} />
          <Link path="/services">Услуги</Link>
          <img src={slide_right} alt="Стрелка" style={{ margin: "0 10px" }} />
          {service.name}
        </h3>
        <ServiceInfo service={service} />
      </div>
    </div>
  );
};
export { Service };
