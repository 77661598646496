import { Link } from "components";
import styles from "./NotFoundPage.module.scss";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <div className={styles.notfound}>
      <Helmet title="Ошибка">
        <link rel="canonical" href="*" />
        <meta
          name="description"
          content="Shalfey : Страница отсутствует или не найдена."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>Такой страницы нет!</h1>
      <Link path="/">Перейти на главную?</Link>
    </div>
  );
};

export { NotFoundPage };
