import styles from "./Services.module.scss";
import { MainMenu } from "components";
import React, { useEffect, useState } from "react";
import { getNoun } from "plugins/getNoun";
import { axiosProduct } from "plugins/axios";
import { Helmet } from "react-helmet";
import { Service } from "./Service";

const Services = () => {
  const [services, setServices] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState("");

  const getServiceTypes = async () => {
    let result = await axiosProduct.getServiceTypes();
    setTypes(result.service_types || []);
  };

  const getServices = async () => {
    let result = await axiosProduct.getServices(selectedTypes);
    setServices(result || []);
  };

  useEffect(() => {
    getServiceTypes();
    getServices();
  }, [selectedTypes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={styles.content}>
      <Helmet title="Услуги">
        <meta name="description" content="Shalfey : Услуги" />
        <meta
          name="keywords"
          content={types.map((item) => item.name).toString()}
        />
        <link rel="canonical" href="/services" />
      </Helmet>
      <div className={styles.menu}>
        <MainMenu selectServices="true" />
      </div>
      <div className={styles.services_part}>
        <div className={styles.title}>
          <h1 onClick={() => setSelectedTypes("")}>Услуги</h1>
          <h2>
            {services?.count || 0}{" "}
            {getNoun(services?.count || 0, "услуга", "услуги", "услуг")}
          </h2>
        </div>
        <div>
          {types?.map((item, index) => (
            <div
              key={index}
              className={styles.all_type}
              onClick={() => setSelectedTypes(item.pk)}
            >
              <div>
                <img src={item.icon_url} alt={item.name} />
              </div>
              <h1
                className={
                  selectedTypes === item.pk ? styles.selected_h1 : null
                }
              >
                {item.name}
              </h1>
            </div>
          ))}
        </div>
        <div className={styles.services}>
          {services?.results?.map((item, index) => (
            <Service key={index} element={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export { Services };
