import styles from "./ModalСontacts.module.scss";
import { Link, ModalNoButton } from "components";
import tg_gray from "assets/img/tg_gray.svg";
import mail_gray from "assets/img/mail_gray.svg";
import watsap_gray from "assets/img/watsap_gray.svg";

const ModalСontacts = ({ onClose }) => (
  <ModalNoButton
    title="Команда заботы"
    description={
      <div className={styles.modal_wrapper}>
        <Link className={styles.contacts} path="https://t.me/telegram">
          <img src={tg_gray} alt="tg" />
          <h1>Наш Telegram</h1>
        </Link>
        <Link className={styles.contacts} path="https://t.me/telegram">
          <img src={watsap_gray} alt="watsap" />
          <h1>Наш WhatsApp</h1>
        </Link>
        <Link className={styles.contacts} path="mailto:zabota@ecoshalf.com">
          <img src={mail_gray} alt="mail" />
          <h1>zabota@ecoshalf.com</h1>
        </Link>
        <h2 onClick={onClose}>Закрыть</h2>
      </div>
    }
    width="25vw"
    height="45vh"
    close={onClose}
  />
);

export { ModalСontacts };
